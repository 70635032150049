'use client';

import Image from 'next/image';
import Link from 'next/link';

import Tag from '@/components/Tag';
import defaultThumbnail from '@/images/default-thumbnail.png';
import { clickVisitPostPageLink } from '@/utils/analytics/log-event';

import { Props } from './props';

const Component: React.FC<Props> = ({
  id,
  movieImageUrl,
  title,
  tags,
  coachImageUrl,
  name,
  place,
  type,
}) => {
  return (
    <Link
      onClick={() => clickVisitPostPageLink(place, type)}
      className="flex hover:bg-gray-100 active:bg-gray-200"
      href={`/archives/${id}`}
      prefetch={false}
    >
      <div className="relative mr-2 block aspect-video w-[190px] overflow-hidden rounded border border-gray-200">
        <Image
          className="bg-gray-100 object-cover"
          fill
          alt={title}
          src={movieImageUrl ?? (defaultThumbnail as unknown as string)}
        />
      </div>

      <div className="flex-1">
        <div className="my-1 line-clamp-2 text-sm font-semibold text-gray-700">
          {title}
        </div>

        <div className="mb-2 flex flex-wrap gap-y-2 sm:gap-x-2">
          {tags.map(tag => (
            <Tag key={tag} name={tag} />
          ))}
        </div>
        <div className="flex flex-row items-center">
          <div className="relative mr-3 block h-[30px] w-[30px] overflow-hidden rounded-full border-b border-gray-200">
            <Image
              src={coachImageUrl}
              className="bg-gray-100 object-cover"
              fill
              alt={title}
            />
          </div>
          <div className="text-xs font-bold text-gray-700">{name}</div>
        </div>
      </div>
    </Link>
  );
};

export default Component;
