'use client';

import { faTableTennis } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';

import SquareButton from '../SquareButton';

import { Props } from './props';

const Component: React.FC<Props> = ({
  id,
  title,
  imageUrl,
  buttonId,
  buttonName,
  description,
}) => {
  return (
    <div className="my-5 flex flex-col items-center gap-5 sm:flex-row sm:items-start">
      <div className="relative block aspect-video h-full w-full max-w-[300px] rounded border border-gray-200 bg-gray-100 sm:max-w-[240px]">
        <Image
          src={imageUrl}
          fill
          alt={title}
          className="rounded object-cover"
        />
      </div>

      <div className="flex-1 text-gray-600">
        <div className="mb-3 font-bold">{title}</div>
        <div className="mb-5 text-sm">{description}</div>
        <SquareButton
          url={`/courses/${id}/1`}
          caption={buttonName}
          icon={faTableTennis}
          button={{ id: buttonId, type: 'coursePage' }}
        />
      </div>
    </div>
  );
};

export default Component;
