'use client';

import 'swiper/css';
import 'swiper/css/autoplay';

import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Tag from '@/components/Tag';
import defaultThumbnail from '@/images/default-thumbnail.png';
import { clickVisitPostPageLink } from '@/utils/analytics/log-event';

import { Props } from './props';
import styles from './style.module.css';

SwiperCore.use([Autoplay]);

/**
 * サーバーサイドで評価される際にはブラウザ幅の情報がないため、SSR時とhydration後でレイアウトシフトが発生する
 * それを防ぐために、 isInit や ./style.module.css を使ってレイアウトシフトが起きないように工夫している
 */
const Component: React.FC<Props> = ({ posts, buttonId }) => {
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    setIsInit(true);
  }, []);

  return (
    <Swiper
      loop={true}
      spaceBetween={20}
      slidesPerView="auto"
      className="h-auto w-full"
      autoplay={{ delay: 3000 }}
      breakpoints={{
        640: {
          slidesPerView: 3,
        },
      }}
    >
      {posts.map(post => (
        <SwiperSlide
          key={post.id}
          className={classnames('h-auto', !isInit && styles.swiperSlide)}
        >
          <Link
            onClick={() => clickVisitPostPageLink(buttonId)}
            className="block overflow-hidden rounded-lg border border-gray-200 bg-white"
            href={`/archives/${post.id}`}
            prefetch={false}
          >
            <div className="relative block aspect-video w-full rounded-t-lg border-b border-gray-200">
              <Image
                className="bg-gray-100 object-cover"
                src={post.imageUrl ?? (defaultThumbnail as unknown as string)}
                alt={post.title}
                fill
              />
            </div>

            <div className="h-24 p-2">
              <div className="mb-2 line-clamp-3 text-sm font-semibold leading-tight text-gray-700">
                {post.title}
              </div>

              <div className="flex flex-wrap gap-x-2 gap-y-1">
                {post.tags.slice(0, 2).map(tag => (
                  <Tag key={tag} name={tag} />
                ))}
              </div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Component;
