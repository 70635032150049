import classnames from 'classnames';

import { Props } from './props';

const Component: React.FC<Props> = ({ width, colorClass }) => {
  const height = width * 1.4142;

  colorClass = colorClass ?? classnames('bg-gray-400');

  return (
    <div className="mx-1 translate-x-[25%]">
      <div
        className="inline-block overflow-hidden"
        style={{ width: `${width}px` }}
      >
        <div
          className={classnames(colorClass, 'origin-top-left rotate-45')}
          style={{ height: `${height}px` }}
        />
      </div>
    </div>
  );
};

export default Component;
