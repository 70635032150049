'use client';

import Link from 'next/link';

import { clickVisitCategoryPageLink } from '@/utils/analytics/log-event';

import Triangle from './components/Triangle';
import { Props } from './props';

const Component: React.FC<Props> = ({ caption, moreLink, description }) => {
  return (
    <div className="mb-3">
      <div className="flex items-center justify-between border-b border-gray-200">
        <h2 className="flex-1 pb-1 text-lg font-bold text-gray-600">
          {caption}
        </h2>
        {moreLink && (
          <Link
            onClick={() =>
              clickVisitCategoryPageLink(moreLink.place, moreLink.category)
            }
            className="flex items-center p-1.5 text-xs font-thin leading-none text-gray-500 no-underline hover:underline"
            href={moreLink.href}
            prefetch={false}
          >
            もっと見る
            <Triangle width={7} />
          </Link>
        )}
      </div>
      {description && (
        <div className="mt-1 text-sm text-gray-500">{description}</div>
      )}
    </div>
  );
};

export default Component;
