type Props = {
  name: string;
};

const Component: React.FC<Props> = ({ name }) => {
  return (
    <div className="rounded bg-gray-100 px-2 py-0.5 text-xs font-bold text-gray-500">
      {name}
    </div>
  );
};

export default Component;
