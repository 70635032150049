'use client';

import Image from 'next/image';
import Link from 'next/link';

import Tag from '@/components/Tag';
import defaultThumbnail from '@/images/default-thumbnail.png';
import { clickVisitPostPageLink } from '@/utils/analytics/log-event';

import { Props } from './props';

const Component: React.FC<Props> = ({
  id,
  tags,
  title,
  buttonId,
  imageUrl,
}) => {
  return (
    <Link
      onClick={() => clickVisitPostPageLink(buttonId)}
      className="block overflow-hidden rounded border border-gray-200 bg-white"
      href={`/archives/${id}`}
      prefetch={false}
    >
      <div className="relative block aspect-video w-full rounded-t-lg border-b border-gray-200">
        <Image
          className="bg-gray-100 object-cover"
          fill
          src={imageUrl ?? (defaultThumbnail as unknown as string)}
          alt={title}
        />
      </div>

      <div className="p-2">
        <div className="mb-2 line-clamp-3 text-sm font-semibold leading-tight text-gray-700">
          {title}
        </div>

        <div className="flex flex-wrap gap-x-2 gap-y-1">
          {tags.slice(0, 2).map(tag => (
            <Tag key={tag} name={tag} />
          ))}
        </div>
      </div>
    </Link>
  );
};

export default Component;
