'use client';

import Image from 'next/image';
import Link from 'next/link';

import defaultThumbnail from '@/images/default-thumbnail.png';
import { clickVisitPostPageLink } from '@/utils/analytics/log-event';

import { Props } from './props';

const Component: React.FC<Props> = ({
  id,
  imageUrl,
  title,
  name,
  place,
  type,
}) => {
  return (
    <Link
      onClick={() => clickVisitPostPageLink(place, type)}
      className="relative block overflow-hidden rounded border border-gray-200 bg-white"
      href={`/archives/${id}`}
      prefetch={false}
    >
      <div className="relative block aspect-video w-full rounded-t-lg border-b border-gray-200">
        <Image
          className="bg-gray-100 object-cover"
          src={imageUrl ?? (defaultThumbnail as unknown as string)}
          alt={title}
          fill
        />
      </div>
      <div className="absolute top-3 flex h-6 w-[85px] items-center justify-center rounded-r-sm bg-orange-400 text-[10px] font-bold text-white sm:w-28 md:w-24 lg:w-28 lg:text-xs">
        {name}
      </div>
      <div className="p-2">
        <div className="mb-2 line-clamp-3 text-sm font-semibold leading-tight text-gray-700">
          {title}
        </div>
      </div>
    </Link>
  );
};

export default Component;
