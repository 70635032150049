'use client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import useHook from './hook';
import { Props } from './props';

const Component: React.FC<Props> = props => {
  const { url, handleClick, caption, icon } = useHook(props);

  return (
    <Link
      passHref
      href={url}
      prefetch={false}
      onClick={handleClick}
      className="flex justify-center"
    >
      <div className="flex h-10 w-full items-center justify-center gap-3 rounded bg-gradient-to-b from-orange-500 to-orange-600 p-2 text-base font-bold leading-none text-white no-underline hover:opacity-90">
        {caption}
        {icon && <FontAwesomeIcon icon={icon} />}
      </div>
    </Link>
  );
};

export default Component;
