import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_components/BlogPostItem/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_components/CoachItem/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_components/PublishedPostItem/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_components/SlidePost/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_components/TechniqueItem/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/amano-yu-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/amano-yu.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/counter.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/foredrive.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/hiraoka-yoshihiro-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/hiraoka-yoshihiro.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/ishigaki-yuka-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/ishigaki-yuka.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/itagaki-koji-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/itagaki-koji.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/largesize-ball.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/matsudaira-shiho-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/matsudaira-shiho.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/oikawa-mizuki-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/oikawa-mizuki.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/pen.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/racket.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/serve-three.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/serve.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/tsutsuki-receive.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/umemura-yuka-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/umemura-yuka.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/yoshimura-maharu-movie.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/app/(other)/_images/yoshimura-maharu.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/components/LessonCourseItem/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/components/SectionHeader/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/images/icons/android-chrome-256x256.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/images/icons/apple-touch-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/images/logo-with-bg.jpg");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/web/src/images/ogp.png")