import {
  clickVisitCoursePageLink,
  clickVisitLandingPageLink,
} from '@/utils/analytics/log-event';

import { Props } from './props';

const useHook = ({ button, caption, url, icon }: Props) => {
  const handleClick = () => {
    if (button.type === 'landingPage') {
      clickVisitLandingPageLink(button.id);
    } else if (button.type === 'coursePage') {
      clickVisitCoursePageLink(button.id);
    }
  };

  return {
    handleClick,
    caption,
    url,
    icon,
  };
};

export default useHook;
