'use client';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';

import defaultThumbnail from '@/images/default-thumbnail.png';
import { clickVisitBlogPageLink } from '@/utils/analytics/log-event';
import { formatDate } from '@/utils/date-formatter';

import { Props } from './props';

const Component: React.FC<Props> = ({
  date,
  link,
  title,
  buttonId,
  imageUrl,
  isPremiumOnly,
}) => {
  return (
    <Link
      href={link}
      prefetch={false}
      onClick={() => clickVisitBlogPageLink(buttonId)}
      className="flex py-1 hover:bg-gray-100 active:bg-gray-200"
    >
      <div className="relative mr-2 block aspect-video w-[190px] overflow-hidden rounded border border-gray-200 sm:w-[130px]">
        <Image
          fill
          alt={title}
          className="bg-gray-100 object-cover"
          src={imageUrl ?? defaultThumbnail}
        />
      </div>

      <div className="flex-1">
        <div className="mb-1 line-clamp-3 text-sm font-semibold leading-tight text-gray-700">
          {title}
        </div>

        <div className="flex flex-wrap items-center gap-2">
          <time className="whitespace-pre-wrap text-xs leading-none text-gray-600">
            {formatDate(date)}
          </time>

          {isPremiumOnly && (
            <div className="inline-block whitespace-pre-wrap rounded-sm bg-slate-500 px-0.5 py-1 text-xs leading-none text-white">
              <FontAwesomeIcon fixedWidth icon={faLock} />
              会員限定
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default Component;
